import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import { useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';



function MyComponent({ thisHere, defaultZoom }) {
  const containerStyle = {
    width: '400px',
    height: '400px',
    marginBottom: '20px' // Add margin at the bottom
  };

  const defaultCenter = {
    lat: 25.0343, // Latitude of The Bahamas
    lng: -77.3963 // Longitude of The Bahamas
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDrkYswOt0GG2_W-MtKCJ8kR3oEHC2QMh8" 
  });
  // eslint-disable-next-line
  const [map, setMap] = useState(null);
  // eslint-disable-next-line
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [marker, setMarker] = useState(null); // State to keep track of the marker

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapClick = (event) => {
    // Remove the existing marker if it exists
    if (marker) {
      marker.setMap(null);
    }

    // Get the clicked coordinates
    const clickedCoordinates = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    setSelectedCoordinates(clickedCoordinates);
    // Update the latitude and longitude values
    setLatitude(clickedCoordinates.lat);
    setLongitude(clickedCoordinates.lng);
    thisHere.setState({
      longitude: clickedCoordinates.lng,
      latitude: clickedCoordinates.lat
    });
    // Create a marker
    const clickedMarker = new window.google.maps.Marker({
      position: clickedCoordinates,
      map: map
    });
    // Set the marker
    setMarker(clickedMarker);
  };

  const handleInputClick = () => {
    // Do something when the input is clicked
  };

  return isLoaded ? (
    <div style={{ marginBottom: '20px' }}> {/* Add margin at the bottom */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultCenter}
        zoom={defaultZoom} // Set the default zoom level directly
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onMapClick} // Add onClick event listener
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
      <div style={{ marginTop: '20px' }}> {/* Add margin at the top */}
        {/* Display the selected coordinates */}
        <div style={{ marginBottom: '10px' }}> {/* Add margin at the bottom */}
          <span style={{ display: 'inline-block', width: '100px' }}>Latitude:</span>
          <input
            type="text"
            value={latitude}
            onClick={handleInputClick}
            readOnly
            style={{ width: '180px', display: 'inline-block' }}
          />
        </div>
        <div>
          <span style={{ display: 'inline-block', width: '100px' }}>Longitude:</span>
          <input
            type="text"
            value={longitude}
            onClick={handleInputClick}
            readOnly
            style={{ width: '180px', display: 'inline-block' }}
          />
        </div>
      </div>
    </div>
  ) : <></>;
}

export const MyGps = React.memo(MyComponent);

export default class ProtectedTreePermit extends React.Component {
  resource = '/protected_tree_permits'
  form_key = 'protected_tree_permit'

  isMultiple = /multiple/.test(this.props.location.pathname)

  defaults = {
    trees_num: 1
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        multiple_trees: this.isMultiple
      },
    },
  }

  // Delete lines as needed
  // fee           = 99
  free          = false
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'i_am_applicant',
      label: 'I am applying for myself',
      type: 'select:bool'
    },
    {
      name: 'f_name',
      label: 'Applicant first name',
      hide: form.i_am_applicant,
    },
    {
      name: 'm_name',
      label: 'Applicant middle name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'l_name',
      label: 'Applicant last name',
      hide: form.i_am_applicant,
    },
    {
      name: 'address',
    },
    {
      name: 'po_box',
      label: 'P.O. Box'
    },
    {
      name: 'island',
      options: islands
    },
    {
      name: 'telephone',
      type: 'phone'
    },
    {
      name: 'fax',
      type: 'phone',
    },
    {
      name: 'location',
      label: 'Location or site of the tree'
    },
    {
      name: 'gps_coordinates',
      heading: 
               <div>
                   <center>
                       <h5>GPS Coordinates of the Tree</h5>
                       <MyComponent thisHere={this} defaultZoom={11}/>
                   </center>
               </div>   
   },
    {
      name: 'valid_permit',
      label: 'Is the site covered by a valid permit for conduct of excavation or landfil?',
      type: 'select:bool'
    },
    {
      name: 'is_covered',
      label: 'Please provide the permit number',
      type: 'integer',
      hide: form.valid_permit != true 
    },
    {
      name: 'not_covered',
      label: 'What is the reason for the removal of the trees?',
      type: 'textarea',
      hint: 'Example: Description of Operation: Land clearing, construction, removal/transplant/destruction of a tree for construction purposes.',
      hide: form.valid_permit != false
    },
    {
      name: 'land_clearing',
      label: 'Please provide details on the land clearing',
      type: 'textarea'
    },
    {
      name: 'size',
      type: 'decimal',
      label: 'Provide the size of the area (in acres)',
      hint: 'Eg. 0.3 acres'
    },
    {
      name: 'tree_names',
      label: 'Common and Scientific name of protected tree(s)',
      rowLabel: 'Tree',
      max: 1,
      fields: [ {
                  name: 'common_name',
                  label: 'Name/Type'
                },
                {
                  name: 'scientific_name'
                },
                {
                  name: 'age',
                  is: 'integer',
                  //required: false
                },
                {
                  name: 'size',
                  hint: 'Include metric of measurement used',
                  //required: false
                },
                {
                  name: 'height',
                  hint: 'Include metric of measurement used',
                  //required: false
                },
                {
                  name: 'quantity',
                  label: 'Number of Trees',
                }
              ]
    },
    {
      name: 'trees_num',
      label: 'Number of protected trees under the Act',
      type: 'integer',
      disabled: true,
    },
   /* {
      name: 'tree_measurments',
      label: 'Age/size/height of the tree(s)',
      rowLabel: 'Age/size/height of Tree',
      max: 1,
      fields: [
        {
          name: 'tree_name'
        },
        {
          name: 'age',
          is: 'integer',
          required: false
        },
        {
          name: 'size',
          required: false
        },
        {
          name: 'height',
          required: false
        }
      ]
    },*/
    {
      name: 'equipment_type_list',
      label: 'Type of equipment used for harvesting protected tree(s)',
      columns: ['equipment'],
      format: v => v.equipment
    },
    {
      name: 'proposed_method',
      label: 'Proposed method of harvesting protected tree(s)',
      type: 'textarea'
    },
    {
      name: 'invasive_species_removal',
      label: 'Proposed plan for Invasive species removal',
      type: 'textarea'
    },
    {
      name: 'restorative_plan_upload',
      label: 'Planned restorative and reinstatement plans and future land use plans for the site',
      hint: 'Mitigation/Reinforestation/Landscape plan',
      key: 'image.1',
      type: 'file:pdf'
    },
    {
      name: 'invasive_species_upload',
      label: 'Invasive species removal plan',
      key: 'images.2',
      type: 'file:pdf' 
    },
    {
      name: 'authorization_letter_upload',
      label: 'Authorization Letter',
      key: 'images.3',
      type: 'file:pdf' 
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
