import React from 'react'
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useState } from 'react';



function MyComponent({ thisHere, defaultZoom }) {
    const containerStyle = {
      width: '400px',
      height: '400px',
      marginBottom: '20px' // Add margin at the bottom
    };
  
    const defaultCenter = {
      lat: 25.0343, // Latitude of The Bahamas
      lng: -77.3963 // Longitude of The Bahamas
    };
  
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyDrkYswOt0GG2_W-MtKCJ8kR3oEHC2QMh8" 
    });
    // eslint-disable-next-line
    const [map, setMap] = useState(null);
    // eslint-disable-next-line
    const [selectedCoordinates, setSelectedCoordinates] = useState(null);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [marker, setMarker] = useState(null); // State to keep track of the marker
  
    const onLoad = React.useCallback(function callback(map) {
      setMap(map);
    }, []);
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null);
    }, []);
  
    const onMapClick = (event) => {
      // Remove the existing marker if it exists
      if (marker) {
        marker.setMap(null);
      }
  
      // Get the clicked coordinates
      const clickedCoordinates = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      setSelectedCoordinates(clickedCoordinates);
      // Update the latitude and longitude values
      setLatitude(clickedCoordinates.lat);
      setLongitude(clickedCoordinates.lng);
      thisHere.setState({
        longitude: clickedCoordinates.lng,
        latitude: clickedCoordinates.lat
      });
      // Create a marker
      const clickedMarker = new window.google.maps.Marker({
        position: clickedCoordinates,
        map: map
      });
      // Set the marker
      setMarker(clickedMarker);
    };
  
    const handleInputClick = () => {
      // Do something when the input is clicked
    };
  
    return isLoaded ? (
      <div style={{ marginBottom: '20px' }}> {/* Add margin at the bottom */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultCenter}
          zoom={defaultZoom} // Set the default zoom level directly
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onMapClick} // Add onClick event listener
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
        <div style={{ marginTop: '20px' }}> {/* Add margin at the top */}
          {/* Display the selected coordinates */}
          <div style={{ marginBottom: '10px' }}> {/* Add margin at the bottom */}
            <span style={{ display: 'inline-block', width: '100px' }}>Latitude:</span>
            <input
              type="text"
              value={latitude}
              onClick={handleInputClick}
              readOnly
              style={{ width: '180px', display: 'inline-block' }}
            />
          </div>
          <div>
            <span style={{ display: 'inline-block', width: '100px' }}>Longitude:</span>
            <input
              type="text"
              value={longitude}
              onClick={handleInputClick}
              readOnly
              style={{ width: '180px', display: 'inline-block' }}
            />
          </div>
        </div>
      </div>
    ) : <></>;
  }

export const MyGps = React.memo(MyComponent);

export default class EnvironmentClearanceSupplementaryForm extends React.Component {
    

  resource = '/env_clearance_supp_forms'
  form_key = 'env_clearance_supp_form'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = form => {
    return {
        longitude: this.state.longitude,
        latitude: this.state.latitude 
    }
    
  }

  foremost = null


  fields = form => [
    "::Section 1",
    {
        name: 'location_island',
        label: 'Island of proposed property',
        options: islands
    },
    {
        heading: `<p>Please state the Activity as well as the purpose of the project for which the Certificate of Environmental Clearance application is being submitted.</p>`,
    },
    {
        name: 'all_activities_list',
        label: 'List all activities that apply:',
        columns: ['activity'],
        format: v => v.activity
    },
    {
        name: 'purpose',
        type: 'textarea'
    },
    {
        name: 'project_category', 
        label: 'Please select the category of the proposed project:',
        options: ['New', 'Modification']
    },
    {
        name: 'activity_category',
        label: 'Category of intended activity:',
        options: ['Abandonment', 'Decommissioning']
    },
  

    "::SPACER",
    "::Section 2",
    //Section 2
    {
        name: 'postal_address'
    },
    {
        name: 'street_name',
        label: 'Street name and Lot number',
        type: 'textarea'
    },
    "::SPACER",
    {
       name: 'gps_coordinates',
       heading: 
                <div>
                    <center>
                        <h5>Select GPS Coordinates</h5>
                        <MyComponent thisHere={this} defaultZoom={11}/>
                    </center>
                </div>   
    },
    "::SPACER",
    {
        name: 'own_property',
        label: 'Do you own the property on which the activity is intended to be carried out?',
        type: 'select:bool',
    },
    /*{
        name: 'if_yes',
        label: 'Please attach certified copies of Proof of ownership.',
        hide: form.own_property != 'Yes'
    }, */
    {
        name: 'own_property_desc',
        label: 'What is the nature of your interest in this property? Please attach supporting documents, justifying your claim',
        hint: 'e.g. lease',
        type: 'textarea',
        hide: !form.own_property
    },
    {
        name: 'other_owners_list',
        label: 'Names and addresses of adjourning property owners',
        fields: ['first_name', 'last_name', 'address'],
        required: false,
        // format: v => [v.first_name, v.last_name, v.address] 
    },
    {
        name: 'prev_app',
        label: 'Previous Application for Certificate of Environmental Clearance for this site?',
        type: 'select:bool',
    },
    {
        name: 'prev_app_ref_no',
        label: 'Reference No. of most recent application',
        hide: !form.prev_app
    },
    {
        name: 'app_reasons_list',
        label: 'Activity for which Certificate of Environmental Clearance required as well as the purpose of the project.',
        columns: [
            { 
                name:'reason_required',
                type: 'textarea'
    
            }, 
            {
                name: 'purpose_of_project',
                type: 'textarea'
            }
        ],
        // format: v => [v.reason_required, v.purpose_of_project]
    },
    "::SPACER",
    {
        name: 'site_desc',
        label: 'Give an outline description of the physical features of the site',
        type: 'textarea'
    },
    {
        name: 'topography',
        label: 'Topography and gradient',
        hint: 'Generally flat rolling/undulating terrain or hilly',
        type: 'textarea'
         
    },
    {
        name: 'springs',
        label: 'Are there any springs or aquifers in or adjacent to the site?',
        type: 'select:bool',
    },
    {
        name: 'lakes',
        label: 'Are there any lakes or drainage within or adjacent to the project site?',
        type: 'select:bool',
    },
    {
        name: 'ponds',
        label: 'Are there any ponds, reservoirs or wetland areas within or contiguous to the project site?',
        type: 'select:bool',
    },
    {
        name: 'soil_type',
        label: 'What is the predominant soil type?',
        options: ['Clay Based', 'Sand', 'Loam', 'Alluvial']
    },
    {
        name: 'lot_distance',
        label: 'How far is the projected lot from the coast?',
    },
    {
        name: 'land_use',
        label: 'Present site land use',
        options: ['Residential', 'Agricultural', 'Industrial', 'Forestry']
    },
    {
        name: 'estimated_capital',
        label: 'Estimated Project Capital',
        type: 'currency'
    },
    "::SPACER",
    "::Descritpion of Intended Activity",
    {
        name: 'intended_activity',
        label: 'Description of the intended Activity',
        type: 'textarea'
    },
    {
        name: 'total_area',
        label: 'Total area intended for the activity (ha or m2)'
    },
    {
        name: 'area_percentage',
        label: 'Percentage of the total surface area allocated to covered space and paved areas',
    },
    {
        name: 'portable_water_consumption',
        label: 'Process water consumption rate (m3/day)'
    },
    {
        name: 'production_output',
        label: 'Production output rate, if different from above',
        hint: '(metric tons per annum)'
    },
    {
        name: 'commencement_date',
        label: 'Intended commencement date',
        type: 'date'
    },
    "::SPACER",
    "::Site Preparation and Construction Phase",

    {
        name: 'require_earthworks',
        label: 'Does the project site require major earthworks such as clearing cutting excavation grading blasting dredging?',
        type: 'select:bool',
    },
    {
        name: 'earthworks_desc',
        label: 'Give an estimate of the volume of water to be impounded (m3) or the rate of abstraction (m3/day) and the source of this water',
        type: 'textarea',
        hide: !form.require_earthworks
    },
    {
        name: 'roads',
        label: 'Would the site require infrastructure and utility development access to Roads or bridges?',
        type: 'select:bool',
    },
    {
        name: 'sewage_system',
        label: 'Would the site require infrastructure and utility development access to a sewage system?',
        type: 'select:bool',
    },
    {
        name: 'power_transmission',
        label: 'Would the site require infrastructure and utility development access to Power generating or transmission facilities ?',
        type: 'select:bool',
    },
    {
        name: 'telecommunication_installation',
        label: 'Would the site require infrastructure and utility development access to Telecommunications installation?',
        type: 'select:bool',
    },
    {
        name: 'drainage_system',
        label: 'Would the site require infrastructure and utility development access to Modification of a drainage system?',
        type: 'select:bool',
    },
    {
        name: 'require_relocation',
        label: 'Will the project require relocation of people houses facilities from the site?',
        type: 'select:bool',
    },
    {
        name: 'relocation_desc',
        label: 'Please give relocation details',
        type: 'textarea',
        hide: !form.require_relocation
    },
    {
        name: 'project_percentage',
        label: 'What percentage of the intended project area would be cleared of vegetation?',
        type: 'integer',
    },
    {
        name: 'mitigation_measures_list',
        label: 'State mitigation measures for adverse impacts resulting during site preparation and the construction phase',
        columns: [
            {
                name:'mitigation_measure',
                type: 'textarea'
            }
        ],
        format: v => v.mitigation_measure
    },
        

    "::SPACER",
    "::Operational Phase",
    {
        name: 'raw_materials_list',
        label: 'State the required raw/input materials and the quantities/volumes (kg or metric tons/m3) to be kept in stock for the project as well as their respective rates of consumption (kg or metric tons per day/m3 per day)',
        columns: ['item', 'quantity_or_volume'],
        // format: v => [v.item, v.quantity_or_volume]
    },
    {
        name: 'requires_chemicals',
        label: 'Would the activity require any ancillary process related chemicals?',
        type: 'select:bool',
        hint: '(e.g. catalysts, pesticides)'
    },
    {
        name: 'chemicals_desc',
        label: 'If yes, state the quantity (kg or metric tonnes/ m3) and rate of consumption',
        type: 'textarea',
        hide: !form.requires_chemicals
    },
    {
        name: 'final_products_list',
        label: 'State the final products to be derived and the rate of production(metric tons/m3 per year)',
        columns: ['product', 'rate_of_production'],
        // format: v => v.rate_of_production
    },
    {
        name: 'intermediate_products_list',
        label: 'State any intermediate products resulting from this activity indicating the rate of production and the fate.(metric tons/m3 per year)',
        columns: ['product', 'rate_of_production'],
        // format: v => [v.product, v.rate_of_production]
    },
    {
        name: 'production_rate',
        label: 'State the rate of production and method of disposal of domestic solid waste generated during the operational phase.',
        hint: '(metric tons per year)',
        type: 'textarea'
    },
    {
        name: 'solid_waste',
        label: 'List, characterize and quantify (metric tons per year) process related solid waste. State the method(s) and location intended for their disposal.',
        type: 'textarea',
    },
    {
        name: 'generation_estimates',
        label: 'Provide respective estimates for the rate of generation (m3per day) of domestic waste water and sewage. State the respective treatment methods intended for   domestic waste water and sewage as well as their ultimate effluent points.',
        type: 'textarea'
    },
    {
        name: 'water_consumption',
        label: 'State the source and process water consumption rate',
        hint: '(m3 per day)',
        type: 'textarea'
    },
    {
        name: 'would_discharge',
        label: 'Would the activity discharge process related liquid effluent?',
        type: 'select:bool',
    },
    {
        name: 'discharge_desc',
        label: 'Please explain',
        type: 'textarea',
        hide: form.would_discharge != 'Yes'
    },
    {
        name: 'hazardous_substances',
        label: 'Would this activity utilise any hazardous (i.e. toxic, flammable, explosive, radioactive etc.) substances?',
        type: 'select:bool',
    },
    {
        name: 'hazardous_substances_list',
        label: 'Provide a listing of the substances and the quantities to be used or stored.',
        columns: ['substance', 'quantity'],
        hide: !form.hazardous_substances,
        // format: v => [v.substance, v.quantity]
    },
    {
        name: 'require_storage',
        label: 'Would the project require storage of input or waste material on site?',
        type: 'select:bool',
    },
    {
        name: 'require_storage_desc',
        label: 'Give estimates of the quantities (kg or metric tons) for the storage of waste & input material.',
        hide: !form.require_storage,
        type: 'textarea'
    },
    {
        name: 'facilities_desc_list',
        label: 'Describe briefly the facilities allocated for this purpose',
        columns: [
            {
                name:'facility',
                type: 'textarea'
            }
        ],
        format: v => v.facility
    },
    {
        name: 'transport_intended_list',
        label: 'Indicate the mode(s) of transport intended for materials and equipment necessary for the operational phase.',
        columns: ['mode_of_transport', 'equipment'],
        // format: v => [v.mode_of_transport, v.equipment]
    },
    {
        name: 'air_emissions',
        label: 'Will the activity generate air emissions during the operational phases?',
        hint: '(i.e. particulate emissions such as dust or           pollutant gaseous emissions)',
        type: 'select:bool',
    },
    {
        name: 'air_emissions_list',
        label: 'Describe types and sources and provide an estimated emission rate or loading.',
        columns: ['type', 'source', 'emission_rate_or_load'],
        hide: !form.air_emissions,
        // format: v => [v.type, v.source, v.emission_rate_or_load]
        
    },
    {
        name: 'produces_odors',
        label: 'Will the activity routinely produce odours?',
        hint: '(i.e. for more than 1 hour per day)',
        type: 'select:bool',
    },
    {
        name: 'high_noise_levels',
        label: 'Will the activity generate significant levels of noise during its operational phase?',
        hint: '(i.e. for more than 1 hour per day at levels exceeding 60 dB)',
        type: 'select:bool',
    },
    {
        name: 'area_aesthetics',
        label:'Will the project have adverse effects on the aesthetics of the area where it is located (i.e. result in radical changes of the landscape, such as scarring/mass vegetation removal',
        type: 'select:bool',
    },
    {
        name: 'adverse_impacts_list',
        label: 'State mitigation measures for adverse impacts resulting during the operational phase',
        columns: [
            {
                name:'mitigation_measure',
                type: 'textarea'
            }
        ],
        format: v => v.mitigation_measure
    },
    {
        name: 'expected_lifespan',
        label: 'State the expected lifespan of this activity.'
    },
    "::SPACER",
    "::Confidentiality",
    {
        name: 'trade_secret',
        label: 'Do you consider any information provided here to be a trade secret or other confidential business information and that such information be omitted from the Register?',
        type: 'select:bool',
    },
    {
        name: 'trade_secret_desc',
        label: 'Please provide details',
        type: 'textarea',
        hide: !form.trade_secret
    },
    {
        name: 'other_info',
        label: 'Other relevant information',
        type: 'textarea'
    },
   
    "::SPACER",
    "::Uploads",
    {
        name: 'completed_certificate_upload',
        label: 'Completed Certificate of Environmental Clearance Application Form A (signed and dated)',
        type: 'file:all',
        key: 'image.0'
    },
    {
        name: 'property_owner_upload',
        label: 'Proof you are the property owner',
        type: 'file:all',
        key: 'image.1'
    },
    {
        name: 'authorization_letter_upload',
        label: 'Letter of authorization from the property owner, if your are not the owner',
        type: 'file:pdf',
        key: 'image.2'
    },
    {
        name: 'agent_authorization_upload',
        label: 'Letter of Authorisation for agent/consultant (if applicable)',
        type: 'file:pdf',
        key: 'image.3',
        required: false
    },
    "::SPACER",
    "::Where the applicant is a company",
    {
        name: 'environment_application_upload',
        label: 'Certificate of Environmental Clearance Application Form',
        type: 'file:pdf',
        key: 'image.4',
        required: false
    },
    {
        name: 'tax_certificate_upload',
        label: 'Tax Compliance Certificate or other similar documentation',
        type: 'file:all',
        key: 'image.5',
        required: false
    },
    {
        name: 'ownership_proof_upload',
        label: 'Proof of Ownership',
        key: 'image.6',
        type: 'file:all',
        required: false
    },
    {
        name: 'environmental_clearance_upload',
        label: 'Certificate of Environmental Clearence',
        key: 'image.7',
        type: 'file:all',
        required: false
    },
  ]
  
  render = () => <Wizard {...SERVICE_PROPS(this)} /> 
  
}