import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS, fullName } from '@modules/form-wizard/utils'
import passportPhotoExample from '@helpers/passport-photo-example'
import Swal from 'sweetalert2'
import toastr from '@modules/toastr'

export default class FoodNoveltyItemVendor extends React.Component {
  resource = '/food_novelty_vendors'
  form_key = 'food_novelty_vendor'

  disable_fpo = true
  postpaid = false
  free = true
  defaults = {}

  // business_only     = true
  skip_location     = true
  hide_buttons      = {
    status: true,
    deny: false,
    fee: true,
  }
  custom_acceptance = true
  has_aggreement = true

  custom_actions = [
    // {
    //   text: 'Approve Application for Payment',
    //   icon: 'check-square',
    //   roles: [['senior_officer']],
    //   fn: record =>
    //     Swal.fire({
    //       title:
    //         'Are you sure that you would like to approve this application for payment?',
    //       text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
    //       icon: 'question',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       confirmButtonText: 'Confirm',
    //     }).then(async result => {
    //       if (result.isConfirmed) {
    //         try {
    //           await $app.axios.put(this.resource + '/update_payment_amount', {
    //             [this.form_key]: {
    //               form_num: record.form_num,
    //             },
    //           })
    //           toastr.success('Success', 'Application successfully approved for payment')
    //         } catch (err) {
    //           console.error(err)
    //           toastr.error('Error', 'Unable to approve application')
    //         }
    //       }
    //     }),
    // },
    {
      text: 'Approve Application for Payment',
      icon: 'check-square',
      roles: [['senior_officer']],
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application for payment?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            try {
              await $app.axios.put(this.resource + '/update_application', {
                [this.form_key]: {
                  form_num: record.form_num,
                  application_decision: 'approved',
                },
              })
              toastr.success('Success', 'Application successfully approved for payment')
            } catch (err) {
              console.error(err)
              toastr.error('Error', 'Unable to approve application')
            }
          }
        }),
    },
    {
      text: 'Recommend To Cultural Officer',
      icon: 'check-square',
      roles: [['officer']],
      fn: record =>
        Swal.fire({
          title: 'Recommend To Cultural Officer',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I recommend this applicant",
            'denied': "I do not recommend this applicant",
          },
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.value) {
            try {
              await $app.axios.put(this.resource + '/update_application', {
                [this.form_key]: {
                  form_num: record.form_num,
                  application_decision: result.value,
                },
              })
              toastr.success('Success', 'Application status updated successfully')
            } catch (err) {
              console.error(err)
              toastr.error('Error', 'Unable to approve application')
            }
          }
        }),
    },
  ]

  validate = null

  foremost = null

  columns = props => [
    {
      name: 'Payment Set',
      selector: row => row.payment_amount || 'N/A',
      sortable: true,
      searchable: r => r.payment_amount,
    },
  ]

  fields = (form, viewing = false) => {
    const list = []
    
    if (!viewing) {
      list.push({
        heading: `
          <strong>
            Please be advised that this application does not guarantee a
            booth/space at any Junkanoo Parade
          </strong>
        `
      })
    }
    
    list.push(
      ...BUSINESS_FIELDS(form),
      '::Contact Details',
      {
        name: 'i_am_contact',
        type: 'select:bool',
        label: 'I am the primary contact.',
        view: {
          label: 'Applicant is Primary Contact?'
        }
      },
    )

    if (!form.i_am_contact) {
      list.push(
        {
          name: 'contact_first_name',
          label: 'First Name',
        },
        {
          name: 'contact_middle_name',
          label: 'Middle Name',
          required: false,
        },
        {
          name: 'contact_last_name',
          label: 'Last Name',
        },
        {
          name: 'contact_phone_numb',
          label: 'Primary Phone #',
          type: 'phone'
        },
        {
          name: 'contact_work_numb',
          label: 'Work Phone #',
          type: 'phone',
          required: false
        },
        {
          name: 'contact_home_numb',
          label: 'Home Phone #',
          type: 'phone',
          required: false
        },
        {
          name: 'contact_po_box',
          label: 'P. O. Box',
          required: false
        },
        {
          name: 'contact_email',
          type: 'email',
          label: 'Email Address',
        },
        )
      }
      
      list.push(
        '::SPACER',
        {
          name: 'vendor_type',
          options: [
            { label: 'Food', value: 'food' },
            { label: 'Novelty Items', value: 'novelty' }
        ],
      },
      {
        name: 'stand_position',
        options: [
          { label: 'Booth - $250', value: 'booth' },
          { label: 'Space - $125', value: 'space' },
          { label: 'Mobile Food Truck - $300', value: 'mobile food truck' },
        ],
      },
      {
        name: 'preferred_parade_list',
        label: 'Preferred Parade(s)',
        multi: true,
        options: [
          'Boxing Day',
          'New Years Day',
          'Junior Junkanoo',
        ],
      },
      {
        name: 'products_list',
        columns: ['product'],
        format: v => v.product
      },
      
      '::Uploads',
      {
        key: 'image.0',
        name: 'food_handler_cert_upload',
        label: `Food Handler's Certificate`,
        type: 'file:all',
        hide: form.vendor_type != 'food'
      },
      {
        key: 'image.1',
        name: 'passport_drivers_license_upload',
        label: `Passport or Driver's License`,
        type: 'file:all',
        hint: passportPhotoExample
      },
      {
        key: 'image.2',
        name: 'nib_card_upload',
        label: 'NIB Card',
        type: 'file:all',
      }
    )

    return list
  }

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
