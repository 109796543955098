import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import countries from '@constants/countries'

import ModelAImg from '@images/rent-to-own/modelA-cap.png'
import ModelBImg from '@images/rent-to-own/modelB-cap.png'
import ModelCImg from '@images/rent-to-own/modelC-cap.png'
import passportPhotoExample from '@helpers/passport-photo-example'

const ModelPhotoExample = (
<div className= 'flex' style={{justifyContent: 'center'}}>
{/* <strong>Select one of the models for more details.</strong> */}
<div className='flex flex-row items-center' style={{flexWrap: 'wrap'}}>
  <a className='flex flex-row items-center mr-1' href="https://api.pilot-qa.gov.bs/system/public_attachments/file_uploads/000/000/004/original/ModelA.pdf?1693547165" target="_blank" rel='noreferrer'>
    <img className='w-32 md:w-40 lg:w-48' src={ModelAImg} alt='Model A Housing Diagram'/>
  </a>
	<a className='flex flex-row items-center mr-1' href="https://api.pilot-qa.gov.bs/system/public_attachments/file_uploads/000/000/005/original/ModelB.pdf?1693547769" target="_blank" rel='noreferrer'>
    <img className='w-32 md:w-40 lg:w-48' src={ModelBImg} alt='Model B Housing Diagram'/>
  </a>
  <a className='flex flex-row items-center' href="https://api.pilot-qa.gov.bs/system/public_attachments/file_uploads/000/000/006/original/ModelC.pdf?1693547786" target="_blank" rel='noreferrer'>
    <img className='w-32 md:w-40 lg:w-48' src={ModelCImg} alt='Model C Housing Diagram'/>
  </a>
</div>
</div>
)

export default class RentToOwn extends React.Component {
  resource = '/rent_to_owns'
  form_key = 'rent_to_own'
  defaults = {}

  // Delete lines as needed
  // fee           = 99
  free          = true
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  newProvidenceSubdivisions =  [
    '3716NP Adelaide Village',
    '3768NP Excellence Estate I',
    '3884NP Dignity (Destini) Gardens Subdivision I',
    '5054NP Fire Trail I',
    '5059NP Ardastra Estates 65 Residential Lots',
    '5054NP Occupational Survey Fire Trail I',
    '5395NP Strachan Hills Estate',
    '5654NP Pastel Gardens',
    // New Providence
    'The Renaissance at Carmichael',
    'PINECREST ESTATES',
    'Pine Forest',
    'West Heights',
    'Heritage',
  ]

  eleutheraSubdivisions =  [
    'Ocean Hole-Rock Sound',
    'Alice Town-Hatchet Bay',
  ]

  abacoSubdivisions =  [
    '1999AB Central Pines Estate 2',
    '2249AB Crown Haven Extension',
    '2480AB Crossing Rocks Subdivision',
  ]

  subDivisionOptions = form => {
    if (form.subdivision_island == 'New Providence') {
      return this.newProvidenceSubdivisions }
    else if(form.subdivision_island == 'Eleuthera') {
      return this.eleutheraSubdivisions }
    else if(form.subdivision_island == 'Abaco') {
      return this.abacoSubdivisions
    }

    return []
  }

  fields = form => [
    // "::Personal Information",
    // {
    //     name: 'first_name',
    // },
    // {
    //     name: 'last_name',
    // },
    // {
    //     name: 'NIB Number',
    //     type: 'integer',
    // },
    // {
    //     name: 'Date of birth',
    //     type: 'date',
    // },
    // {
    //     name: 'country_of_birth',
    //     options: countries,
    // },
    // {
    //     name: 'island_of_birth',
    //     options: islands,
    // },
    // {
    //     name: 'country_of_citizenship',
    //     options: countries,
    // },
    // {
    //     name: 'phone_number',
    //     type: 'phone',
    // },
    ":: Current Home Addresss",
    {
        name: 'house_number',
				label: 'House No.',
        type: 'integer',
    },
    {
        name: 'street_address',
    },
    {
        name: 'po_box',
        label: 'P.O. Box',
        required: false
    },
    {
        name: 'country',
        options: countries,
    },
    {
        name: 'island',
        options: islands,
        hide: form.country != 'Bahamas' || form.country == null
    },
    {
        name: 'home_phone_number',
        type: 'phone',
    },
    ":: Identification Information",
    {
        name: 'passport_number',
    },
    {
        name: 'passport_expiration_date',
        type: 'date',
    },
    {
        name: 'license_number',
				label: 'Drivers Licence #'
    },
    // {
    //     name: 'NIB Photo',
    //     key: 'image.0',
    //     type: 'file:pdf'
    // },
    {
        name: 'photo_upload',
        label: 'Passport photo',
        key:'image.0',
        type: 'file:image',
        hint: 'Passport photo, white background, and dark colored articles of clothing',
				required: false,
    },
    {
        name: 'passport_upload',
        label: 'Passport',
        key:'image.1',
        type: 'file:image',
        tooltip: 'Only the information page is required',
        hint: passportPhotoExample,
				required: false,
    },
    ":: Subdivision & Housing Model",
    {
      name: 'subdivision_island',
      label: 'Select Island',
      options: [
        'New Providence',
        'Eleuthera',
        'Abaco',
      ],
    },
    {
      name: 'subdivision',
      options: this.subDivisionOptions(form),
    },
    // ":: Housing Model",
    {
      name: 'housing_model_desc',
      heading: 
        <div className='mb-4'>
          <center>
            <h5>Click one of the models below for more details</h5>
            {ModelPhotoExample}
          </center>
        </div>   
    },
    {
        name: 'housing_model',
        // hint: ModelPhotoExample,
				options: [
					'Model A',
					'Model B',
					'Model C',
				],
    },
    "::Employment Information",
    {
        name: 'employer',
        label: 'Employer / Name of Company'
    },
    {
        name: 'industry',
				options: [
					'Banking',
					'Insurance',
					'Tourism',
					'Retail',
					'Customs',
					'Defence Force',
					'Immigration',
					'Public Service',
					'Police',
					'Self Employed',
					'Technology',
					'Media-Communication',
					'Other',
				],
    },
    {
        name: 'position',
    },
    {
        name: 'work_phone_number',
        type: 'phone',
    },
    {
        name: 'pre_approved_letter_upload',
        label: 'Blank Pre-approved letter',
        key: 'image.2',
        type: 'file:all',
				required: false,
    },
    {
        name: 'job_letter_upload',
        label: 'Job Letter (Not older than 1 month)',
        key: 'image.3',
        type: 'file:all',
				required: false,
    },
    {
        name: 'paystub_upload',
        label: 'Paystub (Most recent paystub for the full month)',
        key: 'image.4',
        type: 'file:all',
				required: false,
    },
    {
        name: 'loan_upload',
        label: 'Print of existing external loans and credit facilities',
        key: 'image.5',
        type: 'file:all',
				required: false,
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
