import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class InstrumentsDeclaration extends React.Component {
  resource = '/instruments_declarations'
  form_key = 'instruments_declaration'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'f_name',
        label: 'First Name of Operator',
        hide: form.i_am_applicant != false
    },
    {
        name: 'm_name',
        label: 'Middle Name of Operator',
        required: false,
        hide: form.i_am_applicant != false
    },
    {
        name: 'l_name',
        label: 'Last Name of Operator',
        hide: form.i_am_applicant != false
    },
    {
        name: 'serial_no',
        label: 'Serial Number'
    },
    {
        name: 'manufacturer',
        label: 'Aircraft Manufacturer'
    },
    {
        name: 'manufacture_date',
        label: 'Date of Manufacture',
        type: 'date',
        showYearDropdown:true
    },
    {
        name: 'max_weight',
        label: 'Maximum Certificated Take-off Weight (MTOW)'
    },
    {
        name: 'max_seat',
        label: 'Maximum approved passenger seating configuration (MAPSC)'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
