import React, { Fragment } from 'react'
import { ValidatorForm } from 'react-form-validator-core'
import Modal from 'react-responsive-modal'
import Swal from 'sweetalert2'
import moment from 'moment'

// import InputField from '@shared/form-fields/input'
import DateField from '@shared/form-fields/date'
import SelectField from '@shared/form-fields/select'
import Loader from '@shared/loader'
import toastr from '@modules/toastr'
import Wizard from '@modules/form-wizard'
import islands from '@constants/islands'
import SERVICE_PROPS from '@helpers/extract-service-props'
import findSelected from '@helpers/find-selected'
import AdminActions from './_admin-actions'

class BahamahostRegistration extends React.Component {
  state = {
    profile: '',
    session_numb: '',
    session_date: '',
    venue: '',
    record: null,
    loading: false,
  }

  async componentDidMount () {
    try {
      const { data } = await $app.axios.get('/profiles/')
      this.setState({ profile: data })
    } catch (error) {
      console.error(error)
    }
  }
  // The Senior Officer Role is the Trainer role for MOTA
  resource = '/bahama_host_csps'
  form_key = 'bahama_host_csp'

  postpaid = false
  defaults = {
    // exempt_payment: true,
  }
  
  skip_location = true
  custom_acceptance = false

  hide_columns = ['Name']
  columns = props => [
    // {
    //   name: 'Payment Exempt?',
    //   selector: row => row.exempt_payment,
    //   sortable: true,
    //   searchable: r => r.exempt_payment,
    //   cell: r => r.exempt_payment
    //     ? <span style={{ color: 'red' }}>Yes</span>
    //     : <span>No</span>
    // },
    {
      name: 'Name',
      selector: 'user.name',
      sortable: true,
      cell: r => {
        let fName
        let lName
        if (r.company_form) {
          const details = JSON.parse(r.employee_details);
          fName = details.first_name
          lName = details.last_name
        } else {
          fName = r.user?.first_name
          lName = r.user?.last_name
        }

        return (
          <span onClick={props.view(r)} data-action>
            {`${lName}, ${fName}`.capitalize()}
          </span>
        )
      },
    },
    {
      name: 'Company Name',
      selector: row => row.company_form?.company_name || 'N/A',
      sortable: true,
      searchable: r => r.company_form?.company.name,
    },
    {
      name: 'Program Type',
      selector: row => row.industry_type?.capitalize() || 'N/A',
      sortable: true,
      searchable: r => r.industry_type,
    },
    {
      name: 'Session Number',
      selector: row => row.session_numb || 'Not Set',
      sortable: true,
      searchable: r => r.session_numb,
    },
    {
      name: 'Session Date',
      selector: row => row.session_date,
      cell: r => (
        <span>{moment(r?.session_date).format('MMMM Do YYYY') || 'N/A'}</span>
      ),
      sortable: true,
      searchable: r => r.session_date,
    },
  ]

  fee = ({ industry_type, in_business }) => {
    switch (industry_type) {
      // case 'BahamaHost General':
      case 'Tour Guide Training':
        return 150
      case 'BahamaHost Recertification':
        return 75
      case 'Water Sports Training':
        if(in_business) {return 0} else {return 25}
        // if(!in_business) {return 25}
      case 'Certificate Reprint':
        return 5
      default:
        return 150
    }
  }

  // free = ({ industry_type, in_business }) => {
  //   if (industry_type == 'Water Sports Training' && in_business) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  free = false


  hide_buttons = {
    status: true,
    deny: true,
    email: $app.roles?.includes('senior_officer'),
  }

  // filter_records = r => $app.is_citizen
  //   || r.island_location === this.state.profile.island
  //   || this.state.profile.island === 'New Providence'

  // before_apply = () => {
  //   const { profile } = this.state;

  //   const employer = profile.addresses[1]?.business_name || null;

  //   if (!profile.education_level && !employer) {
  //     return "In order to apply for this form, both the Employment Details and Education Details sections in the Profile tab must be filled out.";
  //   }
  // };

  custom_actions = [
    {
      text: 'Enter Session Details',
      icon: 'clipboard-list',
      test: r => r.payment_status && $app.roles?.includes('officer'),
      fn: record => this.setState({ record }),
    },
    {
      text: 'Approve Application',
      icon: 'check-square',
      test: r =>
        r.payment_status &&
        r.application_decision == 'processing' &&
        $app.roles?.includes('officer'),
      fn: record =>
        Swal.fire({
          title:
            'Are you sure that you would like to approve this application?',
          text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.isConfirmed) {
            await this.on.approve(record)
          }
        }),
    },
    {
      text: 'View Exemption Details',
      icon: 'clipboard',
      test: r => r.exempt_payment && $app.roles?.includes('officer'),
      fn: record =>
        Swal.fire({
          title: 'Exemption Details',
          html: `<span style="float:left"><strong>Reason: </strong></span>  <span>${record?.exempt_reason}</span>`,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }),
    },
    {
      text: 'Deny',
      icon: 'ban',
      test: r => r.payment_status && $app.roles?.includes('officer'),
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Denial',
          text: 'Are you sure you want to deny this application?',
          confirmButtonText: 'Deny',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.deny(record)
          }
        }),
    },
    {
      text: 'On Hold',
      icon: 'pause-circle',
      test: r => r.payment_status && $app.roles?.includes('officer'),
      fn: record =>
        Swal.fire({
          icon: 'error',
          title: 'Confirm Hold',
          text: 'Are you sure you want to put this application on Hold?',
          confirmButtonText: 'Hold',
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            await this.onHold(record)
          }
        }),
    },
    // Trainer Action
    {
      text: 'Grade Status',
      icon: 'check-square',
      test: r =>
        r.payment_status &&
        $app?.current_user?.roles?.includes('senior_officer') &&
        (r.application_decision == 'officer viewed' || r.application_decision == 'deferred'),
      fn: record => {
        Swal.fire({
          icon: 'info',
          title: 'Update application status',
          input: "select",
          inputOptions: {
            approved: 'Pass',
            denied: 'Failed',
            defer: 'Defer',
          },
          inputPlaceholder: "Select a grade",
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_application', {
                [form_key]: {
                  form_num: record.form_num,
                  application_decision: result.value,
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = result.value

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire(result.value, `The application status set to ${result.value}`, 'success')
            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error with the status change of the application',
                'error'
              )
            }
          }
        })
      },
    },
      // Trainer Action
    {
      text: 'Change Programme Type',
      icon: 'exchange-alt',
      test: r =>
        r.payment_status &&
        $app?.current_user?.roles?.includes('senior_officer') &&
        (r.application_decision == 'officer viewed' || r.application_decision == 'deferred'),
      fn: record => {
        Swal.fire({
          icon: 'info',
          title: 'Change Programme Type',
          input: "select",
          inputOptions: {
            'Public Service Driver Training': 'Public Service Driver Training',
            'Tour Guide Training': 'Tour Guide Training',
            'Water Sports Training': 'Water Sports Training',
            'BahamaHost General': 'BahamaHost General',
            'BahamaHost Recertification': 'BahamaHost Recertification',
            'Certificate Reprint': 'Certificate Reprint',
          },
          inputPlaceholder: "Select a programme type",
          showCancelButton: true,
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_industry_type', {
                [form_key]: {
                  form_num: record.form_num,
                  industry_type: result.value,
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = result.value

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire(result.value, `The application status set to ${result.value}`, 'success')
            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error with the status change of the application',
                'error'
              )
            }
          }
        })
      },
    },
  ]

  deny = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'denied',
          session_numb: record.session_numb || 0,
          session_date: record.session_date || new Date(),
          venue: record.venue || 'Virtual',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'denied'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application denied')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to deny application')
      return
    }

    try {
      await axios.post('/emails', {
        email: record.user.email,
        subject: 'MyGateway Portal Update - BahamaHost',
        message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            We regret to inform you that the session you selected is no longer available. 
            Please revisit your calendar in MyGateway to select a new date or call the Helpdesk at (242) 604 4357 or toll-free (242) 300 5717 for further assistance.
            <br/>
            Thank you for choosing MyGateway
          `,
      })

      toastr.success('Success', 'Confirmation email successfully sent')
    } catch (err) {
      console.error(err)
      toastr.error('Error', 'Unable to send email')
    }

    this.setState({ loading: false })
  }

  onHold = async record => {
    this.setState({ loading: true })

    const { props, resource, form_key } = this
    const { history, location } = props
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'on hold',
          session_numb: record.session_numb || 0,
          session_date: record.session_date || new Date(),
          venue: record.venue || 'Virtual',
        },
      })

      if (location.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'on hold'

        history.replace(location.pathname, { ...location.state, view })
      }

      toastr.success('Success', 'Application placed on Hold')
    } catch (error) {
      console.error(error)
      toastr.error('Error', 'Unable to hold application')
      return
    }

    this.setState({ loading: false })
  }

  validate = form => {
    if (form.reason == 'Other') {
      return { reason: form.other_reason }
    }
  }

  fields = form => [
    {
      name: 'session_numb',
      label: 'Session Number',
      hide: true,
      view: true,
    },
    {
      name: 'session_date',
      label: 'Start Date of Session',
      hide: true,
      view: true,
    },
    {
      name: 'company',
      hide: true,
      view: {
        element: (value, form) => form.company_form ? (
          <Fragment>
            <h5 className='sub'>Company Details</h5>
            <div className='form-wizard-form-table table-responsive'>
              <table className='table table-striped table-striped-bg-black'>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Business Licence #</th>
                    <th>Company Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{form.company_form?.company_name || form.company_form?.trading_name}</td>
                    <td>{form.company_form?.bl_number}</td>
                    <td>{form.company_form?.company_address}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : null,
      },
    },
    {
      name: 'employee_details',
      label: 'Employee Details',
      hide: true,
      fields: [
        { name: 'first_name', label: 'First Name' },
        { name: 'last_name', label: 'Last Name' },
        { name: 'nib', label: 'NIB #' },
        { name: 'position', label: 'Position' },
        { name: 'email', label: 'Email' },
      ],
      view: {
        element: (value, form) => {
          const details = JSON.parse(form.employee_details);
          
          return form.company_form ? (
            <Fragment>
              <h5 className='sub'>Employee Details</h5>
              <div className='form-wizard-form-table table-responsive'>
                <table className='table table-striped table-striped-bg-black'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>NIB #</th>
                      <th>Position</th>
                      <th>Email Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{`${details?.first_name} ${details?.last_name}`}</td>
                      <td>{details?.nib}</td>
                      <td>{details?.position}</td>
                      <td>{details?.email}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>
          ) : null
        }
      },
    },
    {
      name: 'reason',
      label: 'Reason for Application',
      options: [
        'Public Service Driver',
        'Professional Tour Guide',
        'Water Sports',
        'General',
        'Other',
      ],
      callback: async v => {
        if (v == 'Professional Tour Guide') {
          await Swal.fire(
            'Notice',
            `
              *Prerequisite for Tour Guiding course is a current
              (3 years or less) BahamaHost Certificate.
            `,
            'info'
          )
        } else if (v == 'Water Sports') {
          await Swal.fire(
            'Notice',
            `
              *Prerequisite for Water Sports course is a current
              (3 years or less) BahamaHost Certificate.
            `,
            'info'
          )
        }

        if (v != 'Other') {
          return { other_reason: '' }
        }
      },
    },
    {
      name: 'other_reason',
      label: 'Other',
      hide: form.reason != 'Other' && form.reason !== form.other_reason,
      view: false,
    },
    {
      name: 'venue',
      // hide: true,
      // view: true,
      options: [
        { label: 'Virtual', value: 'virtual' },
        { label: 'In-Person', value: 'in-person' },
      ],
    },
    {
      name: 'time_of_session',
      label: 'Time of Session',
      options: ['Morning', 'Evening'],
      // options: ['Morning', 'Afternoon', 'Evening'],
    },
    {
      name: 'industry_type',
      label: 'Program Type',
      options: [
        {label: "Public Service Driver Training ($150)", value: 'Public Service Driver Training'},
        {label: "Tour Guide Training ($150)", value: 'Tour Guide Training'},
        {label: `Water Sports Training`, value: 'Water Sports Training'},
        {label: "BahamaHost General ($150)", value: 'BahamaHost General'},
        {label: "BahamaHost Recertification ($75)", value: 'BahamaHost Recertification'},
        {label: "Certificate Reprint ($5)", value: 'Certificate Reprint'},
        // 'BahamaHost Seminar',
      ],
    },
    {
      name: 'in_business',
      type: 'select:bool',
      label: 'Are you currently in the Water Sports business?',
      hide: form.industry_type != 'Water Sports Training',
      required: false,
      hint: `${!form.in_business ? 'If not in the <b>Water Sports business</b> the certificate <b>fee</b> is <b>$25</b>' : ''}`,
    },
    {
      name: 'previous_session_numb',
      label: 'Previous Session Number',
      hide: form.industry_type != 'Water Sports Training' && form.industry_type != 'Tour Guide Training' && form.industry_type != 'Certificate Reprint' && form.industry_type != 'BahamaHost Recertification',
      // view: true,
    },
    {
      name: 'cert_date',
      type: 'date',
      label: 'Certificate Date',
      hide: form.industry_type != 'Certificate Reprint',
      // view: true,
    },
    {
      name: 'cert_type',
      label: 'Type of Certificate (Reprint)',
      hide: form.industry_type != 'Certificate Reprint',
      options: [
        'Public Service Driver Training',
        'Tour Guide Training',
        'Water Sports Training',
        'BahamaHost General',
        'BahamaHost Recertification',
        'Certificate Reprint',
        // 'BahamaHost Seminar', 
      ],
    },
    {
      name: 'island_location',
      options: islands,
      callback: v => {
        const other = {}

        if (form.other_reason) {
          other.reason = form.other_reason
        }
        return other
      },
    },
    {
      name: 'island_sub_location',
      label: '',
      hide: !['Andros', 'Eleuthera'].includes(form.island_location),
      options: [
        'North',
        'Central',
        'South',
      ],
    },
    {
      name: 'photo_upload',
      key: 'image.1',
      label: 'Government Issued ID',
      type: 'file:image',
      // key: f => f.image,
    },
    {
      name: 'exempt_payment',
      label:
        'By clicking here, you are certifying that this application is exempt from payment.',
      type: 'checkbox',
      required: false,
      hide: true,
      save: true,
    },
    {
      name: 'non_refundable',
      type: 'checkbox',
      view: false,
      required: true,
      label: `
        <span style="color: red; font-weight: 800; text-transform: uppercase;">Registration fee is nonrefundable and remains
        valid for a period of six (6) months.</span>
      `,
    }
    // {
    //   name: 'exempt_reason',
    //   label: 'What is your reason for payment exemption?',
    //   type: 'textarea',
    // },
  ]

  hidden_fields = () => [
    {
      name: 'pdf_upload',
      key: 'image.0',
      type: 'file:pdf',
      label: 'BahamaHost Certification',
    },
  ]


  closeModal = () => this.setState({ record: null })

  on = {
    select: v => this.setState({ session_numb: v.length && v[0].value }),
    input: name => ev =>
      this.setState({ [name]: ev.target.value.replace(/[^0-9a-z ]/gi, '') }),

    date: val => this.setState({ session_date: val }),

    submitSessionDetails: async () => {
      this.setState({ loading: true })

      const { state, props, resource, form_key } = this
      const { history, location } = props
      const { record, session_numb, venue, session_date } = state
      const { axios } = $app

      try {
        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
            session_numb,
            venue,
            session_date,
          },
        })

        if (location.state?.view) {
          const view = { ...location.state.view }
          view.application_decision = 'approved'
          view.fields[4].value = session_numb
          view.fields[5].value = venue
          view.fields[6].value = moment(session_date).format('MMMM Do YYYY')

          history.replace(location.pathname, { ...location.state, view })
        }

        toastr.success(
          'Success',
          'Session Number, Session Date and Venue successfully set'
        )
      } catch (error) {
        console.error(error)
        toastr.error(
          'Error',
          'Unable to set Session Number, Session Date and Venue'
        )
        return
      }

      try {
        await axios.post('/emails', {
          email: record.user.email,
          subject:
            'MyGateway Portal Update - BahamaHost Certified Service Professionals',
          message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Congratulations! Your BahamaHost application has been approved and we are so excited to have you join us.
            Your details are as follows:
            <br/>
            <ul>
            <li>Session Number: <strong>${record?.session_numb}</strong></li>
            <li>Session Date: <strong>${moment(record?.session_date).format(
              'Do MMMM, YYYY'
            )}</strong></li>
            <li>Venue: <strong>${record?.venue}</strong></li>
            </ul>
            <br/><br />
            Your assigned trainer will be in contact with you shortly to provide the login details and instructions.
            <br/>

            If you need assistance, please contact us at: Telephone: (242) 302-2000 ext 2441, Email: info.bahamahost@bahamas.com
            <br/>
            Thank you for using the MyGateway Portal.
          `,
        })

        toastr.success('Success', 'Confirmation email successfully sent')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email')
      }

      this.setState({ loading: false, record: null })
    },

    approve: async record => {
      this.setState({ loading: true })

      const { props, resource, form_key } = this
      const { history, location } = props
      const { axios } = $app

      try {
        await axios.put(resource + '/update_application', {
          [form_key]: {
            form_num: record.form_num,
            application_decision: 'approved',
          },
        })

        if (location.state?.view) {
          const view = { ...location.state.view }
          view.application_decision = 'approved'

          history.replace(location.pathname, { ...location.state, view })
        }

        toastr.success('Success', 'Application successfully approved')
      } catch (error) {
        console.error(error)
        toastr.error('Error', 'Unable to approve application')
        return
      }

      try {
        await axios.post('/emails', {
          email: record.user.email,
          subject:
            'MyGateway Portal Update - BahamaHost Certified Service Professionals',
          message: `
            Dear ${record.user.first_name.capitalize()},
            <br/>
            Congratulations! Your BahamaHost application has been approved and we are so excited to have you join us.
            Your details are as follows:
            <br/>
            <ul>
            <li>Session Number: <strong>${record?.session_numb}</strong></li>
            <li>Session Date: <strong>${moment(record?.session_date).format(
              'Do MMMM, YYYY'
            )}</strong></li>
            <li>Venue: <strong>${record?.venue}</strong></li>
            </ul>
            <br/><br />
            Your assigned trainer will be in contact with you shortly to provide the login details and instructions.
            <br/>

            If you need assistance, please contact us at: Telephone: (242) 302-2000 ext 2441, Email: info.bahamahost@bahamas.com
            <br/>
            Thank you for using the MyGateway Portal.
          `,
        })

        toastr.success('Success', 'Confirmation email successfully sent')
      } catch (err) {
        console.error(err)
        toastr.error('Error', 'Unable to send email')
      }

      this.setState({ loading: false })
    },
  }

  venue_options = [
    { label: 'Virtual', value: 'virtual' },
    { label: 'In-person', value: 'in-person' },
  ]

  render() {
    const { state, props, on, closeModal } = this
    const metadata = props.service.metadata[0].data

    const sessions = metadata?.filter(d => d.active)
      .map(d => ({ value: d?.session_numb, label: d?.session_numb }))

    return (
      <React.Fragment>
        {/* {console.log(this)} */}
        {/* {console.log($app)} */}
        <Loader loading={state.loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={state.record}
          style={{ width: 480 }}
          onClose={closeModal}
          center
        >
          <div className='modal-header'>
            <h5 className='modal-title'>Set Details</h5>
          </div>
          <ValidatorForm onSubmit={on.submitSessionDetails}>
            <div className='modal-body'>
              <div className='form-group form-show-validation'>
                <label htmlFor='session_numb'>
                  Session Number
                  <span className='required-label'>*</span>
                </label>
                <SelectField
                  onChange={on.select}
                  name='session_numb'
                  options={sessions || []}
                  values={findSelected(sessions, state.session_numb)}
                  value={state.session_numb}
                  validators={['required']}
                  errorMessages={['Session Number must be selected']}
                  className='form-control w-100'
                />
              </div>
              <div className='form-group form-show-validation'>
                <label htmlFor='venue'>
                  Venue
                  <span className='required-label'>*</span>
                </label>
                {/* <InputField
                  name='venue'
                  value={state.venue}
                  onChange={on.input('venue')}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                  disabled
                  required
                /> */}
                <SelectField
                  name='venue'
                  value={state.venue}
                  options={this.venue_options}
                  onChange={ev => this.setState({ venue: ev?.[0]?.value })}
                  className='form-control'
                  validators={['required']}
                  errorMessages={['Required']}
                  required
                />
              </div>
              <div className='form-group form-show-validation'>
                <label htmlFor='session_date'>
                  Start Date of Session
                  <span className='required-label'>*</span>
                </label>
                <DateField
                  type='date'
                  className='form-control'
                  name={'end_date'}
                  onChange={on.date}
                  selected={state.session_date}
                  value={state.session_date}
                  validators={['required']}
                  errorMessages={['Required']}
                  dateFormat='do MMMM, yyyy'
                  showYearDropdown
                />
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-round mr-2'
                aria-label='Close'
                onClick={closeModal}
              >
                Cancel
              </button>
              <input
                className='btn custom-btn btn-round'
                type='submit'
                value='Submit'
              />
            </div>
          </ValidatorForm>
        </Modal>
      </React.Fragment>
    )
  }
}

export default AdminActions(BahamahostRegistration)
