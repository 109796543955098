import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'

export default class ComplianceDeclaration extends React.Component {
  resource = '/compliance_declarations'
  form_key = 'compliance_declaration'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'f_name',
        label: 'First Name of Operator',
        hide: form.i_am_applicant != false
    },
    {
        name: 'm_name',
        label: 'Middle Name of Operator',
        required: false,
        hide: form.i_am_applicant != false
    },
    {
        name: 'l_name',
        label: 'Last Name of Operator',
        hide: form.i_am_applicant != false
    },
    {
        name: 'aoc_ref',
        label: 'AOC Ref. No'
    },
    {
        name: 'postholder',
        label: 'Postholder for continuing Airworthiness',
        hint: 'Name'
    },
    {
        name: 'rev_status',
        label: 'CAME Ref. No. and Issue/Rev Status:',
    },
    {
        name: 'submisison_reason',
        label: 'Reason forthe submission of compliance',
        type: 'textarea'
    },
    /*
    "::Cap Air 02 - Aoc Operators’ With Sub-contracted Continuing Airworthiness Management Chapter 13 Support",
  
    
    {
        heading: 
            <p>This section is applicable only if the operator sub-contracts any continuing airworthiness management tasks. Reference must be made to CAP AIR 02 for CAA-B policies
and guidance. If not applicable just complete Item 1. Below to record this and the remainder of this section left blank </p>
    
    } */
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
