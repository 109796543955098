import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import countries from '@constants/countries'
import { useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function MyComponent({ thisHere, defaultZoom }) {
    const containerStyle = {
      width: '400px',
      height: '400px',
      marginBottom: '20px' // Add margin at the bottom
    };
  
    const defaultCenter = {
      lat: 25.0343, // Latitude of The Bahamas
      lng: -77.3963 // Longitude of The Bahamas
    };
  
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyDrkYswOt0GG2_W-MtKCJ8kR3oEHC2QMh8" 
    });
    // eslint-disable-next-line
    const [map, setMap] = useState(null);
    // eslint-disable-next-line
    const [selectedCoordinates, setSelectedCoordinates] = useState(null);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [marker, setMarker] = useState(null); // State to keep track of the marker
  
    const onLoad = React.useCallback(function callback(map) {
      setMap(map);
    }, []);
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null);
    }, []);
  
    const onMapClick = (event) => {
      // Remove the existing marker if it exists
      if (marker) {
        marker.setMap(null);
      }
  
      // Get the clicked coordinates
      const clickedCoordinates = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      setSelectedCoordinates(clickedCoordinates);
      // Update the latitude and longitude values
      setLatitude(clickedCoordinates.lat);
      setLongitude(clickedCoordinates.lng);
      thisHere.setState({
        longitude: clickedCoordinates.lng,
        latitude: clickedCoordinates.lat
      });
      // Create a marker
      const clickedMarker = new window.google.maps.Marker({
        position: clickedCoordinates,
        map: map
      });
      // Set the marker
      setMarker(clickedMarker);
    };
  
    const handleInputClick = () => {
      // Do something when the input is clicked
    };
  
    return isLoaded ? (
      <div style={{ marginBottom: '20px' }}> {/* Add margin at the bottom */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultCenter}
          zoom={defaultZoom} // Set the default zoom level directly
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onMapClick} // Add onClick event listener
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
        <div style={{ marginTop: '20px' }}> {/* Add margin at the top */}
          {/* Display the selected coordinates */}
          <div style={{ marginBottom: '10px' }}> {/* Add margin at the bottom */}
            <span style={{ display: 'inline-block', width: '100px' }}>Latitude:</span>
            <input
              type="text"
              value={latitude}
              onClick={handleInputClick}
              readOnly
              style={{ width: '180px', display: 'inline-block' }}
            />
          </div>
          <div>
            <span style={{ display: 'inline-block', width: '100px' }}>Longitude:</span>
            <input
              type="text"
              value={longitude}
              onClick={handleInputClick}
              readOnly
              style={{ width: '180px', display: 'inline-block' }}
            />
          </div>
        </div>
      </div>
    ) : <></>;
  }
  
  export const MyGps = React.memo(MyComponent);
  

export default class ApprovedTraining extends React.Component {
  resource = '/approved_trainings'
  form_key = 'approved_training'
  defaults = {}

  // Delete lines as needed
  fee           = 99
  free          = true
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
        name: 'i_am_applicant',
        label: 'I am applying for myself',
        type: 'select:bool'
    },
    {
        name: 'name',
        label: 'Name of Applicant/Holder',
        hide: form.i_am_applicant != false
    },
    {
        name: 'permanent_address',
        hint: 'Street or PO Box Number',
        hide: form.i_am_applicant != false
    },
    {
        name: 'telephone',
        type: 'phone',
        hide: form.i_am_applicant != false
    },
    {
        name: 'fax',
    },
     {
        name: 'city'
     },
     {
        name: 'island',
        label: 'Island/State/Province',
        options: islands
     },
     {
        name: 'postal_code',
        label: 'Postal.Mailing Code'
     },
     {
        name: 'country',
        options: countries
     },
     {
        name: 'operations_base',
        heading: 
                 <div>
                     <center>
                         <h5>Location of Main Operations Base</h5>
                         <MyComponent thisHere={this} defaultZoom={11}/>
                     </center>
                 </div>   
     },
     {
        name: 'training_course',
        label: 'Identification of Training Courses',
        columns: ['Course Title']
     },
     {
        name: 'special_equip',
        label: 'Equipment Requiring Special Approval',
        columns: ['Equipment Description', 'Location']
     },
     "::Additional Application Attachments",
     {
      name: 'training_upload',
      label: 'TO Training & Procedures Manual',
      key: 'image.0',
      type: 'file:pdf'
     },
     {
      name: 'report_upload',
      label: 'Conformance Report',
      key: 'image.1',
      type: 'file:pdf'
     },
     {
      name: 'facility_upload',
      label: 'Facility Description & Layout',
      key: 'image.2',
      type: 'file:all'
     },
     {
      name: 'records_upload',
      label: 'Proposed student & Staff Records',
      key: 'image.3',
      type: 'file:pdf'
     },
     {
      name: 'grad_upload',
      label: 'Proposed Graduation Certificate',
      key: 'image.4',
      type: 'file:all'
     },
     {
      name: 'transcript_upload',
      label: 'Proposed transcript Records',
      key: 'image.5',
      type: 'file:pdf'
     },
     {
      name: 'management_upload',
      label: 'Management Resumes',
      key: 'image.6',
      type: 'file:pdf'
     },
     {
      name: 'instructor_upload',
      label: 'Instructor Resumes',
      key: 'image.7',
      type: 'file:pdf'
     },
     {
      name: 'instructor_train_upload',
      label: 'Instructor Training Records',
      key: 'image.8',
      type: 'file:pdf'
     },
     {
      name: 'qa_upload',
      label: 'Quality Assurance System',
      key: 'image.9',
      type: 'file:all'
     }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
