import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import { useState } from 'react'



function MyComponent({ thisHere, defaultZoom }) {
  const containerStyle = {
    width: '400px',
    height: '400px',
    marginBottom: '20px' // Add margin at the bottom
  };

  const defaultCenter = {
    lat: 25.0343, // Latitude of The Bahamas
    lng: -77.3963 // Longitude of The Bahamas
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDrkYswOt0GG2_W-MtKCJ8kR3oEHC2QMh8" 
  });
  // eslint-disable-next-line
  const [map, setMap] = useState(null);
  // eslint-disable-next-line
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [marker, setMarker] = useState(null); // State to keep track of the marker

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMapClick = (event) => {
    // Remove the existing marker if it exists
    if (marker) {
      marker.setMap(null);
    }

    // Get the clicked coordinates
    const clickedCoordinates = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    setSelectedCoordinates(clickedCoordinates);
    // Update the latitude and longitude values
    setLatitude(clickedCoordinates.lat);
    setLongitude(clickedCoordinates.lng);
    thisHere.setState({
      longitude: clickedCoordinates.lng,
      latitude: clickedCoordinates.lat
    });
    // Create a marker
    const clickedMarker = new window.google.maps.Marker({
      position: clickedCoordinates,
      map: map
    });
    // Set the marker
    setMarker(clickedMarker);
  };

  const handleInputClick = () => {
    // Do something when the input is clicked
  };

  return isLoaded ? (
    <div style={{ marginBottom: '20px' }}> {/* Add margin at the bottom */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultCenter}
        zoom={defaultZoom} // Set the default zoom level directly
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onMapClick} // Add onClick event listener
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
      <div style={{ marginTop: '20px' }}> {/* Add margin at the top */}
        {/* Display the selected coordinates */}
        <div style={{ marginBottom: '10px' }}> {/* Add margin at the bottom */}
          <span style={{ display: 'inline-block', width: '100px' }}>Latitude:</span>
          <input
            type="text"
            value={latitude}
            onClick={handleInputClick}
            readOnly
            style={{ width: '180px', display: 'inline-block' }}
          />
        </div>
        <div>
          <span style={{ display: 'inline-block', width: '100px' }}>Longitude:</span>
          <input
            type="text"
            value={longitude}
            onClick={handleInputClick}
            readOnly
            style={{ width: '180px', display: 'inline-block' }}
          />
        </div>
      </div>
    </div>
  ) : <></>;
}

export const MyGps = React.memo(MyComponent);

export default class DerelictVehicle extends React.Component {
  resource = '/derelict_vehicles'
  form_key = 'derelict_vehicle'
  defaults = {}

  // Delete lines as needed
  // fee           = 99
  free          = true
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    "::Vehicle Information",
    {
      name: 'vehicle_make',
      options: [
            "Abarth",
            "Alfa Romeo",
            "Aston Martin",
            "Audi",
            "Bentley",
            "BMW",
            "Bugatti",
            "Cadillac",
            "Chevrolet",
            "Chrysler",
            "Citroën",
            "Dacia",
            "Daewoo",
            "Daihatsu",
            "Dodge",
            "Donkervoort",
            "DS",
            "Ferrari",
            "Fiat",
            "Fisker",
            "Ford",
            "Honda",
            "Hummer",
            "Hyundai",
            "Infiniti",
            "Iveco",
            "Jaguar",
            "Jeep",
            "Kia",
            "KTM",
            "Lada",
            "Lamborghini",
            "Lancia",
            "Land Rover",
            "Landwind",
            "Lexus",
            "Lotus",
            "Maserati",
            "Maybach",
            "Mazda",
            "McLaren",
            "Mercedes-Benz",
            "MG",
            "Mini",
            "Mitsubishi",
            "Morgan",
            "Nissan",
            "Opel",
            "Peugeot",
            "Porsche",
            "Renault",
            "Rolls-Royce",
            "Rover",
            "Saab",
            "Seat",
            "Skoda",
            "Smart",
            "SsangYong",
            "Subaru",
            "Suzuki",
            "Tesla",
            "Toyota",
            "Volkswagen",
            "Volvo"
      ]
    },
    {
      name: 'vehicle_model'
    },
    {
      name: 'vehicle_color'
    },
    {
      name: 'license_number',
      label: 'Vehicle License Plate Number',
      hint: 'if available',
      required: false
    },
    {
      name: 'vehicle_id',
      label: 'Vehicle Identification Number(VIN)',
      hint: 'if available',
      required: false
    },

    "::Location of Derelict Vehicle",
    {
      name: 'island',
      options: islands
    },
    {
      name: 'street_address'
    },
    {
      name: 'gps_coordinates',
      heading: 
               (<div>
                   <center>
                       <h5>Pinpoint Location on Map</h5>
                       <MyComponent thisHere={this} defaultZoom={11}/>
                   </center>
               </div>),
      view: false 
    },

    "::Condition of the Vehicle",
    {
      name: 'vehicle_damaged',
      label:'Is the vehicle damaged?',
      type: 'select:bool'
    },
    {
      name: 'vehicle_abandoned',
      label: 'Does the vehicle appear to have been abandoned?',
      type: 'select:bool'
    },
    {
      name: 'safety_hazard',
      label: 'Is the vehicle creating a safety hazard?',
      type: 'select:bool'
    },

    "::Duration",
    {
      name: 'how_long',
      label: 'How long has the vehicle been at this location?',
      options: [
        'Less than a week',
        '1 to 2 weeks',
        '3 to 4 weeks',
        'More than a month'
      ]
    },

    "::Additional Information",
    {
      name: 'additional_info',
      label: 'Additional Comments or Observations',
      type: 'textarea',
      required: false
    },

    "::Uploads",
    {
      name: 'vehicle_photo_upload',
      label: 'Upload a Photo of the Vehicle',
      key: 'image.0',
      type: 'file:image'
    },
    {
      name: 'extra_photo_upload',
      label: 'Upload an additional Photo of the Vehicle',
      key: 'image.1',
      type: 'file:image'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
